const produtora = {
    texto_geral_pt: 'A Reduto Filmes é uma produtora audiovisual sediada no Rio de Janeiro e fundada em 2021 por Adler Costa, Bruno Ribeiro, Laís Diel e Tuanny Medeiros. Desde sua criação, seus trabalhos obtiveram ampla circulação, no Brasil e no exterior, e foram selecionados para editais e laboratórios de produção e desenvolvimento de projetos. Os filmes da produtora foram exibidos em festivais como o Festival Internacional de Berlim, Festival Internacional de Rotterdam, Doclisboa, Festival de Brasília, Mostra de Cinema de Tiradentes e Olhar de Cinema. Entre alguns dos prêmios conquistados estão o Urso de Prata no Festival Internacional de Berlim com "Manhã De Domingo" (2022) e Melhor Curta-Metragem no Doclisboa com "Gargaú" (2021).',
    texto_geral_en: `Reduto Filmes is a production company based in Rio de Janeiro and founded in 2021 by Adler Costa, Bruno Ribeiro, Laís Diel and Tuanny Medeiros. Since its creation, its work has been widely screened in Brazil and abroad and has been selected to receive funding and participate in development labs. The company's films have been shown at festivals such as the Berlin International Film Festival, Rotterdam International Film Festival, Doclisboa, Brasília Film Festival, Tiradentes Film Festival and Olhar de Cinema. Some of the awards received include the Silver Bear at the Berlin International Film Festival for "Sunday Morning" (2022) and Best Short Film at Doclisboa for "Gargaú" (2021).`,
    membros : [
        {
            nome: "Adler Costa",
            imagem: "/produtora/adler.jpg",
            texto_pt: 'Adler Costa é bacharel em Direito (UFRJ), pós-graduado em Direito e Processo Penal (ABDCONST) e Mestrando em Direito Penal e Criminologia (UERJ). Foi produtor do curta "Gargaú", vencedor do prêmio de Melhor Curta-Metragem no Doclisboa (2021) e distribuidor do curta "Manhã de Domingo", vencedor do Urso de Prata no Festival de Berlim (2022). É sócio-fundador da Reduto Filmes, onde produz e distribui, ao lado de Laís Diel, os projetos da produtora. ',
            texto_en: `Adler Costa has a bachelor's degree in Law from the Federal University of Rio de Janeiro (UFRJ), a postgraduate degree in Criminal Law and Procedure (ABDCONST) and is a master's student in Criminal Law and Criminology in the State University of Rio de Janeiro (UERJ). He was a producer in the short film "Gargaú", winner of the Best Short Film award at Doclisboa (2021) and a distributor of the short film "Sunday Morning", winner of the Silver Bear at the Berlin Film Festival (2022). He is a partner and co-founder of Reduto Filmes, where he produces and distributes the company's projects alongside Laís Diel.`
        },
        {
            nome: "Bruno Ribeiro",
            imagem: "/produtora/bruno.jpg",
            texto_pt: `Bruno Ribeiro é graduando do curso de Cinema e Audiovisual da UFF e roteirista na Rede Globo. Realizou curtas-metragens que tiveram ampla circulação em festivais nacionais e internacionais, tendo recebido diversos prêmios, como o de Melhor Curta-Metragem no Doclisboa com "Gargaú" (2021) e Urso de Prata no Festival de Berlim com "Manhã de Domingo" (2022). Atualmente o realizador se dedica ao desenvolvimento de seu primeiro longa, "Sião", que já participou de laboratórios e eventos de mercado como o BrLab (onde recebeu o Prêmio de Desenvolvimento da distribuidora Vitrine Filmes), Torino Film Lab, CineMundi e Ventana Sur. É sócio-fundador da Reduto Filmes.`,
            texto_en: `Bruno Ribeiro is a Cinema student at the Fluminense Federal University (UFF) and a screenwriter at Rede Globo. He has directed short films that have circulated widely at Brazilian and international festivals, and has won several awards, such as Best Short Film at Doclisboa for "Gargaú" (2021) and the Silver Bear at the Berlin International Film Festival for "Sunday Morning" (2022). He is a partner and co-founder of Reduto Filmes and is currently working on the development of his first feature, "Sião", which has already taken part in labs and market events such as BrLab (where it received the Development Award from distributor Vitrine Filmes), Torino Film Lab, CineMundi and Ventana Sur.`
        },
        {
            nome: "Laís Diel",
            imagem: "/produtora/lais.jpg",
            texto_pt: `Laís Diel é bacharel em Cinema e Audiovisual pela Universidade Federal Fluminense (UFF). Atua nas áreas de Produção e Produção Executiva de projetos audiovisuais e acumula seis anos de experiência na área. Como Assistente de Produção Executiva, trabalhou em produtoras como A Fábrica, Conspiração Filmes, Migdal Filmes, Maria Farinha Filmes e Morena Filmes. É sócia-fundadora da Reduto Filmes, produtora onde atua como Produtora Executiva e cujo portfólio acumula os curtas-metragens “Pele Suja Minha Carne” (2016), “BR3” (2018), “Gargaú” (2021) e “Manhã de Domingo” (2022), vencedor do Urso de Prata no Festival de Berlim. É também produtora do projeto de longa ficcional “Jéssica”, selecionado para o BrLab Features 2023.`,
            texto_en: `Laís Diel has a degree in Cinema from the Fluminense Federal University (UFF). She works with production and executive production and has six years of experience. As an Executive Production Assistant, she has worked in production companies such as A Fábrica, Conspiração Filmes, Migdal Filmes, Maria Farinha Filmes and Morena Filmes. She is a partner and co-founder of Reduto Filmes, where she acts as Executive Producer and whose portfolio includes the short films "Dirty Skin" (2016), "BR3" (2018), "Gargaú" (2021) and "Sunday Morning" (2022), winner of the Silver Bear at the Berlin International Film Festival. She is also the producer of the feature film project "Jessica", selected for BrLab Features 2023.`
        },
        {
            nome: "Tuanny Medeiros",
            imagem: "/produtora/tuanny.jpg",
            texto_pt: `Tuanny Medeiros atua como roteirista e assistente de roteiro em projetos de ficção e documentário. É bacharel em Letras pela Universidade do Estado do Rio de Janeiro (UERJ), foi bolsista da segunda turma Fulbright Brasil New Voices para roteiristas estreantes e estudou audiovisual no Cinema Nosso. É sócia-fundadora da Reduto Filmes e foi co-roteirista do curta de ficção "Manhã de Domingo" (2022), premiado com o Urso de Prata no Festival de Berlim. Teve seu primeiro projeto de longa-metragem, “Jéssica”, selecionado para o BrLab Features 2023.`,
            texto_en: `Tuanny Medeiros is a screenwriter and script assistant on fiction and documentary projects. She has a degree in English and Literature from the State University of Rio de Janeiro (UERJ), received a scholarship from Fulbright Brazil New Voices and studied cinema at Cinema Nosso. She is a partner and co-founder of Reduto Filmes and co-wrote the short fiction film "Sunday Morning" (2022), which won the Silver Bear at the Berlin International Film Festival. Her first feature film project, “Jessica”, was selected for the BrLab Features 2023.`
        }

    ]
}

export default produtora